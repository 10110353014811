import React, { useState } from 'react';
import useSWR from 'swr';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  CircularProgress,
  IconButton,
  Button,
  Typography,
  ListProps,
  Switch,
  FormControlLabel,
  ListItemProps,
} from '@mui/material';
import * as api from '../../../api/chat';
import { Chat } from '../../../types/chat';
import { useModel } from '../../providers/ModelProvider';

type ChatItemProps = ListItemProps & {
  chat: Chat;
};

const ChatItem: React.FC<ChatItemProps> = ({ chat, ...props }) => {
  return (
    <ListItem {...props}>
      <ListItemText primary={chat.userName} secondary={chat.userName} />
    </ListItem>
  )
};


type ChatListProps = ListProps & {

};

const ChatList: React.FC<ChatListProps> = ({ ...props }) => {
  const { modelId } = useModel();
  const { data, error, mutate } = useSWR<Chat[]>(modelId ? api.utrls.List({ modelId }) : null);
  if (!data) return <CircularProgress />;

  return (
    <List {...props}>
      {data.map((chat) => {
        return (
          <ChatItem
            key={chat.id}
            chat={chat}
          />
        );
      })}
    </List>
  );
};

export default ChatList;
