import cfg from '../config';
import { getToken } from './auth';
import { Chat } from '../types/chat';

export const utrls = {
  List: (params: { modelId: string }) => `/api/v1/chats?modelId=${params.modelId}`,
};

export const list = async (params: { modelId: string }): Promise<Chat[]> => {
  const url = `${cfg.apiUrl}/api/v1/chats?modelId=${params.modelId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}