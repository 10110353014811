import React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../../../components/layout/Page';
import { ModelProvider } from '../../../components/providers/ModelProvider';
import { Grid } from '@mui/material';
import ChatList from '../../../components/containers/model/ChatList';

const ModelChatsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <ModelProvider id={id as string}>
      <Page title='Chats'>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <ChatList />
          </Grid>
          <Grid item xs={10}>
            Messages
            {/* <Chat /> */}
          </Grid>
        </Grid>
      </Page>
    </ModelProvider>
  );
};

export default ModelChatsPage;